<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t("hourlyWageMsgs.manageHourlyWage") }}
              </h4>
            </div>

            <a
              href="#"
              class="btn btn-primary"
              @click.prevent="openPostWageModal()"
            >
              {{ $t("hourlyWageMsgs.setNewWage") }}
            </a>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <b-table
                :fields="tableColumns"
                :items="allHourlyWages"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
              >
                <template #cell(hourlyRate)="data">
                  {{ $n(data.item.hourly_rate, "currency", "de-DE") }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                    class="border border-success text-success"
                    variant="none"
                    v-if="data.item.is_current"
                    >{{ $t("hourlyWageMsgs.current") }}
                  </b-badge>
                </template>

                <template #cell(startFrom)="data">
                  {{ formatDate(data.item.start_from) }}
                </template>

                <template #cell(createdAt)="data">
                  {{ formatDateTime(data.item.created_at) }}
                </template>

                <template #cell(createdBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                  <span v-else>--</span>
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data" style="min-width: 50px">
                  <a
                    href="#"
                    class="svg-icon mr-2"
                    @click.prevent="openPostWageModal(data.item.id)"
                  >
                    <i class="far fa-edit text-info" />
                  </a>
                  <a
                    class="svg-icon"
                    href="#"
                    @click.prevent="confirmDeleteHourlyWage(data.item.id)"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                :value="currentPage"
                :total-rows="totalHourlyWages"
                :per-page="pageSize"
                @change="onPageChange"
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <post-hourly-wage-modal
      :showModal.sync="modals.showPostWageModal"
      :hourlyWageId="modals.selectedWageId"
      @close="hidePostWageModal"
      @closeUpdate="hidePostWageUpdateModal"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DEFAULT_PAGE_SIZE } from "../../common/constants";
import { formatDateTime, formatDate } from "../../common/utils";
import PostHourlyWageModal from "./PostHourlyWageModal";

export default {
  name: "HourlyWageList",

  components: { PostHourlyWageModal },

  data() {
    return {
      allHourlyWages: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalHourlyWages: 0,

      isLoading: false,

      modals: {
        selectedWageId: null,
        showPostWageModal: false,
      },
    };
  },

  methods: {
    ...mapActions(["getHourlyWages", "deleteHourlyWage"]),
    formatDateTime,
    formatDate,

    async fetchHourlyWages(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getHourlyWages({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...params,
      });
      this.allHourlyWages = response.data.results;
      this.currentPage = pageNum;
      this.totalHourlyWages = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchHourlyWages(pageNum);
    },

    onSearch(params) {
      this.fetchHourlyWages(
        1,
        params.searchTerm ? { search: params.searchTerm } : {}
      );
    },

    openPostWageModal(wageId = null) {
      this.modals.selectedWageId = wageId;
      this.modals.showPostWageModal = true;
    },

    hidePostWageModal() {
      this.modals.showPostWageModal = false;
      this.modals.selectedWageId = null;
    },

    hidePostWageUpdateModal() {
      this.hidePostWageModal();
      this.fetchHourlyWages(this.currentPage);
    },

    async confirmDeleteHourlyWage(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("hourlyWageMsgs.deleteConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteHourlyWage(id);
          const refreshPage =
            this.allHourlyWages.length > 1
              ? this.currentPage
              : this.currentPage - 1;
          this.fetchHourlyWages(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.deletedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchHourlyWages();
  },

  computed: {
    tableColumns() {
      return [
        {
          key: "hourlyRate",
          label: `${this.$t("hourlyWageMsgs.hourlyWage")} (${this.$t(
            "hourlyWageMsgs.euroPerHour"
          )})`,
        },
        { key: "status", label: this.$t("status") },
        { key: "startFrom", label: this.$t("hourlyWageMsgs.startDate") },
        { key: "createdAt", label: this.$t("hourlyWageMsgs.setOn") },
        { key: "createdBy", label: this.$t("hourlyWageMsgs.setBy") },
        {
          key: "actions",
          tdClass: "text-right",
          thStyle: { minWidth: "80px" },
        },
      ];
    },
  },
};
</script>
