import { render, staticRenderFns } from "./HourlyWageList.vue?vue&type=template&id=472d68eb&"
import script from "./HourlyWageList.vue?vue&type=script&lang=js&"
export * from "./HourlyWageList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports