<template>
  <div class="container-fluid">
    <b-modal
      ref="post-hourly-wage-modal"
      size="lg"
      :title="$t('hourlyWageMsgs.setNewWage')"
      hide-footer
      centered
      static
      lazy
      tall
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="hourlyRate"
                    >{{ $t("hourlyWageMsgs.hourlyRate") }} ({{
                      $t("hourlyWageMsgs.euroPerHour")
                    }})</label
                  >
                  <currency-input
                    class="form-control"
                    id="hourlyRate"
                    :placeholder="35"
                    :precision="2"
                    :allow-negative="false"
                    v-model="hourlyRate"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="startDate">{{
                    $t("hourlyWageMsgs.startDate")
                  }}</label>

                  <b-form-datepicker
                    id="startDate"
                    v-model="startFrom"
                    :locale="getCurrLanguage"
                    required
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{
                    hourlyWageId ? $t("update") : $t("hourlyWageMsgs.set")
                  }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { formatToAPIDate } from "../../common/utils";

export default {
  name: "PostHourlyWageModal",

  props: {
    showModal: Boolean,
    hourlyWageId: Number,
  },

  data() {
    return {
      hourlyRate: null,
      startFrom: formatToAPIDate(moment()),

      isLoading: false,
    };
  },

  methods: {
    ...mapActions(["getHourlyWage", "createHourlyWage", "updateHourlyWage"]),

    resetData() {
      this.hourlyRate = null;
      this.startFrom = formatToAPIDate(moment());
      this.isLoading = false;
    },

    hideModal() {
      this.$emit("close");
      this.resetData();
    },

    hideModalManual() {
      this.$refs["post-hourly-wage-modal"].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs["post-hourly-wage-modal"].hide();
      this.$emit("closeUpdate");
      this.resetData();
    },

    async fetchHourlyWage() {
      this.isLoading = true;

      try {
        const wage = await this.getHourlyWage(this.hourlyWageId);

        this.hourlyRate = wage.data.hourly_rate;
        this.startFrom = wage.data.start_from;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async onSubmit() {
      // TODO: Add validations.

      this.isLoading = true;

      try {
        const data = {
          hourly_rate: this.hourlyRate,
          start_from: this.startFrom
        };
        if (this.hourlyWageId) {
          await this.updateHourlyWage({
            id: this.hourlyWageId,
            data,
          });
          this.makeToast("success", this.$t("hourlyWageMsgs.wageUpdated"));
        } else {
          await this.createHourlyWage(data);
          this.makeToast("success", this.$t("hourlyWageMsgs.wageSet"));
        }
        this.isLoading = false;
        this.hideWithUpdateModal();
      } catch (error) {
        if (error.response.data.start_from) this.makeToast("danger", this.$t("hourlyWageMsgs.duplicateStartDate"));
        else this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(["getCurrLanguage"]),
  },

  watch: {
    showModal(value) {
      if (value) {
        if (this.hourlyWageId) {
          this.fetchHourlyWage();
        }
        this.$refs["post-hourly-wage-modal"].show();
      }
    },
  },
};
</script>
